@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: hsl(var(--p));
    border-radius: 10px;
  }
  
  body {
    overflow-x: hidden;
  }

}
